@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.app,
.content {
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
  margin: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

button {
  transition: all;
  animation-duration: 3s;
}